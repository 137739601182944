import AwardsSection from "./component/AwardsSection";
import Footer from "./component/Footer";
import GalleryScreen from "./component/GalleryScreen";
import Innovations from "./component/Innovations";
import Header from "./component/Header";
import OurJourney from "./component/OurJourney";
import Partners from "./component/Partners";
import SuccessStories from "./component/SuccessStories";
import HeroSection from "./component/HeroSection";

export default function App() {
  return (
    <div>
      <Header />
      <HeroSection />
      <OurJourney />
      <SuccessStories />
      <GalleryScreen />
      <AwardsSection />
      <Innovations />
      <Partners />
      <Footer />
    </div>
  );
}
