import * as React from "react";
const DownArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill="#278579"
      fillRule="evenodd"
      d="M5.5 0c.208 0 .408.079.555.22.148.14.23.331.23.53v18.688l3.372-3.22a.792.792 0 0 1 .556-.22.82.82 0 0 1 .557.22.748.748 0 0 1 .23.531.72.72 0 0 1-.23.531l-4.714 4.5A.79.79 0 0 1 5.5 22a.818.818 0 0 1-.556-.22L.23 17.28a.734.734 0 0 1-.23-.53c0-.2.083-.39.23-.532a.806.806 0 0 1 .557-.22c.208 0 .408.08.556.22l3.371 3.22V.75c0-.199.083-.39.23-.53A.805.805 0 0 1 5.5 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DownArrow;
