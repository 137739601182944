import React from "react";
import nirmal from "../assets/nirmal.png";
import karna from "../assets/karna.png";
import sarla from "../assets/sarala.png";
import bala from "../assets/bala.png";
import linkedin from "../assets/linkedin.png";
import insta from "../assets/insta.png";

export default function Partners() {
  return (
    <div className="p-4 px-28">
      {/* Heading */}
      <div className="flex flex-col text-center">
        <span className="text-[36px] md:text-[96px] leading-[115px] font-barlowcondensed font-light">
          We are together
        </span>
        <span className="text-[#10C06F] text-[24px] leading-[115px] md:text-[40px] font-barlowcondensed font-normal">
          Partners
        </span>
      </div>

      {/* Partner Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 justify-items-center pt-16">
        {/* Partner 1 */}
        <div className="flex flex-col items-center">
          <img
            src={nirmal}
            alt="nirmal"
            className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
          />
          <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
            M. Nirmal Reddy
          </span>
          <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
            Founder & CEO
          </span>
          <div className="flex gap-2 pt-2">
            <img
              src={insta}
              alt="Instagram"
              className="w-6 h-6 md:w-8 md:h-8"
            />
            <img
              src={linkedin}
              alt="LinkedIn"
              className="w-6 h-6 md:w-8 md:h-8"
            />
          </div>
        </div>

        {/* Partner 2 */}
        <div className="flex flex-col items-center">
          <img
            src={karna}
            alt="karna"
            className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
          />
          <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
            M. Karunakar Reddy
          </span>
          <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
            Co-Founder & CTO
          </span>
          <div className="flex gap-2 pt-2">
            <img
              src={insta}
              alt="Instagram"
              className="w-6 h-6 md:w-8 md:h-8"
            />
            <img
              src={linkedin}
              alt="LinkedIn"
              className="w-6 h-6 md:w-8 md:h-8"
            />
          </div>
        </div>

        {/* Partner 3 */}
        <div className="flex flex-col items-center">
          <img
            src={sarla}
            alt="sarla"
            className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
          />
          <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
            K. Sarala
          </span>
          <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
            Director
          </span>
          <div className="flex gap-2 pt-2">
            <img
              src={insta}
              alt="Instagram"
              className="w-6 h-6 md:w-8 md:h-8"
            />
            <img
              src={linkedin}
              alt="LinkedIn"
              className="w-6 h-6 md:w-8 md:h-8"
            />
          </div>
        </div>

        {/* Partner 4 */}
        <div className="flex flex-col items-center">
          <img
            src={bala}
            alt="bala"
            className="w-[150px] h-[150px] md:w-[200px] md:h-[200px]"
          />
          <span className="font-roboto font-normal text-[20px] leading-[26.4px] md:text-[24px] tracking-wide mt-4 text-center">
            Bala Krishna Ch
          </span>
          <span className="tracking-wider text-center text-[14px] md:text-[16px] font-roboto font-normal leading-[17.6px]">
            Director
          </span>
          <div className="flex gap-2 pt-2">
            <img
              src={insta}
              alt="Instagram"
              className="w-6 h-6 md:w-8 md:h-8"
            />
            <img
              src={linkedin}
              alt="LinkedIn"
              className="w-6 h-6 md:w-8 md:h-8"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
