import * as React from "react";
const Gear = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M22.073 0c4.601 0 8.338 3.74 8.338 8.338 0 4.6-3.737 8.339-8.338 8.339-.685 0-1.828-.21-1.957-.233L4.21 13.585C1.714 13.01 0 10.858 0 8.338A5.356 5.356 0 0 1 4.164 3.1L20.124.231C20.246.208 21.389 0 22.074 0Zm0 14.855a6.523 6.523 0 0 0 6.519-6.517 6.524 6.524 0 0 0-6.519-6.516c-.391 0-1.201.123-1.626.204L4.53 4.885c-1.576.366-2.71 1.792-2.71 3.454A3.538 3.538 0 0 0 4.574 11.8l15.869 2.852c.31.056 1.19.202 1.63.202Z"
    />
    <path
      fill="#000"
      d="M5.44 7.466a.874.874 0 0 1 0 1.745.874.874 0 0 1 0-1.746Zm0-1.352a2.224 2.224 0 1 0 0 4.449 2.224 2.224 0 0 0 0-4.449ZM22.255 5.432a2.91 2.91 0 0 1 2.907 2.906 2.91 2.91 0 0 1-2.907 2.907 2.91 2.91 0 0 1-2.907-2.906 2.91 2.91 0 0 1 2.907-2.907Zm0-1.69a4.596 4.596 0 1 0 0 9.193 4.596 4.596 0 0 0 0-9.193Z"
    />
  </svg>
);
export default Gear;
