import React from "react";
import BestEV from "../assets/BestEV.png";
import Innovation from "../assets/Innovation.png";
import FutureEV from "../assets/FutureEV.png";
import IACC from "../assets/IACC.png";
import Prize from "../assets/Prize.png"; // Image for small screens

export default function AwardsSection() {
  return (
    <div className="lg:px-32 md:px-16 py-8">
      {/* Award Icon */}
      <div className="flex items-center justify-around mb-8 w-full">
        {/* Left Divider */}
        <div
          className="flex-grow h-[2px] mx-4"
          style={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderImage:
              "linear-gradient(270deg, #FFFFFF 3.14%, #10C06F 51.81%, #FFFFFF 98.57%) 1",
          }}
        ></div>

        {/* Image in the Center */}
        <img src={Prize} alt="Prize" className="mx-4" />

        {/* Right Divider */}
        <div
          className="flex-grow h-[2px] mx-4"
          style={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderImage:
              "linear-gradient(270deg, #FFFFFF 3.14%, #10C06F 51.81%, #FFFFFF 98.57%) 1",
          }}
        ></div>
      </div>

      {/* Awards Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 justify-evenly">
        {/* IACC Award */}
        <div className="flex flex-col items-center text-center">
          <img src={IACC} alt="IACC" />
          <span className="font-barlowcondensed text-[16px] sm:text-[18px] tracking-wide mt-4">
            IACC
          </span>
          <span className="tracking-wide text-[16px] sm:text-[18px] font-barlowcondensed">
            Energy Saver
          </span>
        </div>

        {/* Best EV Award */}
        <div className="flex flex-col justify-center text-center">
          <img src={BestEV} alt="Best EV" />
          <span className="font-barlowcondensed text-[16px] sm:text-[18px] tracking-wide mt-4">
            Best EV Service
          </span>
          <span className="tracking-wide text-[16px] sm:text-[18px] font-barlowcondensed">
            2024
          </span>
        </div>

        {/* Innovation Award */}
        <div className="flex flex-col items-center text-center">
          <img src={Innovation} alt="Innovation" />
          <span className="font-barlowcondensed text-[16px] sm:text-[18px] tracking-wide mt-4">
            Innovation in
          </span>
          <span className="tracking-wide text-[16px] sm:text-[18px] font-barlowcondensed">
            Electric Vehicles
          </span>
        </div>

        {/* Future EV Award */}
        <div className="flex flex-col items-center text-center">
          <img src={FutureEV} alt="Future EV" />
          <span className="font-barlowcondensed text-[16px] sm:text-[18px] tracking-wide mt-4">
            Sustainable Future
          </span>
          <span className="tracking-wide text-[16px] sm:text-[18px] font-barlowcondensed">
            Electric Transport
          </span>
        </div>
      </div>

      {/* Divider */}
      <div
        className="h-[2px] w-full flex justify-center my-12"
        style={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderImage:
            "linear-gradient(270deg, #FFFFFF 3.14%, #10C06F 51.81%, #FFFFFF 98.57%) 1",
        }}
      ></div>
    </div>
  );
}
