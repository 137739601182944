import React from "react";
import innovationsbg from "../assets/innovationsbg.png";
import EvTrans from "../svgs/evtrans";
import EvLogistics from "../svgs/evlogistics";
import Evcharging from "../svgs/evcharging";

export default function Innovations() {
  return (
    <div
      style={{
        backgroundImage: `url(${innovationsbg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}
      className="flex flex-col justify-between px-28"
    >
      {/* Heading */}
      <p className="text-[32px] md:text-[96px] text-center font-barlowcondensed font-light leading-[115px]">
        OHM Group of Companies
      </p>
      <p className="text-[#0AAC76] text-[24px] md:text-[40px] text-center font-barlowcondensed font-normal leading-[115px]">
        Innovations
      </p>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row justify-around pt-12 md:pt-36 space-y-8 md:space-y-0">
        {/* EV Transportation */}
        <div className="flex flex-col items-center">
          <EvTrans />
          <span className="text-[#173D3D] tracking-wider mt-4 text-center font-roboto font-normal text-[20px] leading-[20px]">
            OHM Automotive Pvt. Ltd.
          </span>
          <span className="tracking-wide text-center font-nova-normal text-[20px] leading-[23.36px]">
            EV Transportation
          </span>
        </div>

        {/* EV Logistics */}
        <div className="flex flex-col items-center">
          <EvLogistics />
          <span className="text-[#173D3D] tracking-wider mt-4 text-center font-roboto font-normal  text-[20px] leading-[20px]">
            OHM E Logistics Pvt. Ltd.
          </span>
          <span className="tracking-wide text-center font-roboto font-normal  text-[20px] leading-[23.36px]">
            Best EV Cab Services
          </span>
        </div>

        {/* EV Charging Solutions */}
        <div className="flex flex-col items-center">
          <Evcharging />
          <span className="text-[#173D3D] tracking-wide mt-1 text-center font-roboto font-normal  text-[20px] ">
            OHM Charging Solutions Pvt. Ltd.
          </span>
          <span className="tracking-wide text-center font-roboto font-normal  text-[20px] leading-[23.36px]">
            One-stop EV Charging Solution
          </span>
        </div>
      </div>
    </div>
  );
}
