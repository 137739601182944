import * as React from "react";
const Mail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={23}
    fill="none"
    {...props}
  >
    <path
      fill="#F7CB30"
      d="M31 3.052c0-.218 0-.545-.108-.763C30.462.981 29.28 0 27.776 0H3.268C1.872 0 .689.981.152 2.29c-.107.217-.215.544-.107.762V19.73c0 1.853 1.504 3.27 3.224 3.27h24.507C29.602 23 31 21.474 31 19.73V3.052Zm-3.224-.872c.322 0 .537.11.752.327l-12.253 8.066c-.43.327-.968.327-1.398 0L2.517 2.507c.215-.218.43-.327.752-.327h24.507Zm0 18.64H3.268c-.645 0-1.075-.545-1.075-1.09V4.905l11.393 7.413a3.673 3.673 0 0 0 1.935.545c.645 0 1.29-.218 1.935-.545l11.5-7.522V19.73c-.107.654-.644 1.09-1.181 1.09Z"
    />
  </svg>
);
export default Mail;
