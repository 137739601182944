import * as React from "react";
const Service = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={53}
    height={53}
    fill="none"
    {...props}
  >
    <path
      fill="#F7CB30"
      d="m9.982 32.96 7.445-2.225c1.784-.532 2.926-2.241 2.649-4.073l-.314-2.796c1.884-.43 3.767-.757 6.305-.72 2.64.04 4.462.371 6.383.907l-.345 2.736c-.23 1.824.861 3.566 2.528 4.15l7.324 2.494c1.01.32 2.128.234 3.1-.31.974-.544 1.596-1.449 1.865-2.51l1.092-5.162c.33-1.722-.202-3.506-1.504-4.744-.901-.825-2.204-1.962-3.362-2.639-4.579-2.758-10.597-4.319-16.94-4.463-6.395-.095-12.406 1.34-17.065 3.961-1.125.593-2.513 1.74-3.438 2.539l-.206.2c-1.237 1.2-1.718 2.868-1.388 4.548l1.04 5.193c.239 1.07.834 1.992 1.74 2.513.956.573 2.02.74 3.091.401Zm-3.1-3.193-1.04-5.193c-.238-1.07.133-2.13.905-2.88l.155-.15c.874-.749 2.158-1.796 3.13-2.34 4.403-2.473 10.108-3.81 16.147-3.67 5.99.088 11.754 1.594 16.032 4.145.956.573 2.209 1.657 3.06 2.431.852.774 1.241 1.897.97 3.06l-1.091 5.162c-.108.405-.265.758-.574 1.058-.103.1-.258.25-.41.298-.513.297-1.072.34-1.628.18l-7.324-2.495c-.909-.318-1.504-1.24-1.338-2.202l.509-3.495c.057-.457-.191-.816-.646-.975-2.274-.693-4.45-1.182-7.596-1.229-3.046-.045-5.235.38-7.529 1.006a.896.896 0 0 0-.674.955l.354 3.558c.138.967-.485 1.872-1.403 2.163l-7.444 2.225c-.56.144-1.118.085-1.621-.227-.452-.362-.85-.876-.944-1.385Z"
    />
  </svg>
);
export default Service;
