import React from "react";
import Card from "./Card";
import Navbar from "./NavBar";
import DownArrow from "../svgs/downarrow";

export default function HeroSection() {
  return (
    <div
    // style={{
    //   backgroundImage: `url(${HeroBg})`,
    //   backgroundSize: "cover",
    //   backgroundPosition: "center",
    // }}
    >
      <Navbar />
      <div className="justify-center items-center flex ">
        <Card />
      </div>
      <div className=" justify-center items-center flex flex-col gap-4 p-4">
        <DownArrow />
        <span className=" text-[16px] text-center font-nova leading-[16px]">Scroll down</span>
      </div>
    </div>
  );
}
