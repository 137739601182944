import React from "react";
export default function Header() {
  return (
    <>
      <header className="bg-[#0B3E56] sticky top-0 z-50">
        <div className="container mx-auto px-4">
          <div className="">
            <div className="justify-center">
              <p className="text-white text-center font-normal tracking-wider font-quicksand ">
                Want to talk with us? Sales: 8142033344, Service: 8142033388
              </p>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
