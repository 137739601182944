import * as React from "react";
const EvLogistics = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={172}
    height={96}
    fill="none"
    {...props}
  >
    <path
      fill="url(#a)"
      d="M78.222.007h84.422c4.909 0 8.901 3.985 8.901 8.902V80.9H69.328v-72C69.328 3.991 73.312 0 78.23 0l-.007.007Z"
    />
    <path
      fill="#A7D16E"
      d="M68.918 22.771v58.51H7.916A7.432 7.432 0 0 1 .485 73.85V62.666c0-4.455 2.626-8.484 6.7-10.282l7.618-3.372a12.717 12.717 0 0 0 6.431-6.342l5.723-12.468a12.73 12.73 0 0 1 11.572-7.423h30.389v-.008Z"
    />
    <path
      fill="#C3E7EC"
      d="M63.12 45.543V28.569H38.53a6.957 6.957 0 0 0-6.312 4.051l-5.723 12.468c-.075.156-.157.298-.231.455H63.12Z"
    />
    <path
      fill="#1D1A45"
      d="M22.256 68.515v12.766H7.923a7.432 7.432 0 0 1-7.43-7.431v-5.335h21.77-.007Z"
    />
    <path
      fill="#E5EFEF"
      d="M32.03 37.992h.485a4.015 4.015 0 0 1 4.014 4.014 4.015 4.015 0 0 1-4.014 4.014h-6.014v-2.5a5.539 5.539 0 0 1 5.537-5.536l-.008.008Z"
    />
    <path fill="#E5EFEF" d="M63.12 45.542H26.495v35.74h36.627v-35.74Z" />
    <path
      fill="#173D3D"
      d="M61.024 49.542h-3.313a.965.965 0 0 1-.962-.963.96.96 0 0 1 .962-.962h3.313a.96.96 0 0 1 .963.962.96.96 0 0 1-.963.963ZM171.545 77.827H22.733V80.9h148.812v-3.074Z"
    />
    <path
      fill="#345655"
      d="M150.109 80.9c0 .127 0 .254-.007.381h-34.963c-.007-.127-.007-.254-.007-.38 0-9.663 7.827-17.497 17.489-17.497s17.496 7.834 17.496 17.497h-.008Z"
    />
    <path
      fill="#173D3D"
      d="M147.11 80.9c0 8.006-6.491 14.49-14.489 14.49-7.999 0-14.49-6.491-14.49-14.49 0-7.998 6.491-14.489 14.49-14.489 7.998 0 14.489 6.491 14.489 14.49Z"
    />
    <path
      fill="#fff"
      d="M141.402 80.9a8.782 8.782 0 1 1-17.564 0 8.782 8.782 0 0 1 17.564 0Z"
    />
    <path
      fill="#345655"
      d="M49.012 80.9c0 .127 0 .254-.008.381H14.042c-.008-.127-.008-.254-.008-.38 0-9.663 7.827-17.497 17.489-17.497s17.496 7.834 17.496 17.497h-.007Z"
    />
    <path
      fill="#173D3D"
      d="M46.005 80.9c0 8.006-6.491 14.49-14.49 14.49-7.998 0-14.49-6.491-14.49-14.49 0-7.998 6.492-14.489 14.49-14.489 7.999 0 14.49 6.491 14.49 14.49Z"
    />
    <path
      fill="#fff"
      d="M40.297 80.9a8.782 8.782 0 1 1-17.564 0 8.782 8.782 0 0 1 17.564 0Z"
    />
    <path
      fill="#10C06F"
      d="M96.916 25.54a8.674 8.674 0 0 0-8.674 8.673c0 1.886.618 3.617 1.646 5.034.019.034.022.072.043.105l5.782 8.673a1.446 1.446 0 0 0 2.405 0l5.783-8.673c.021-.033.024-.071.042-.105a8.537 8.537 0 0 0 1.646-5.034 8.674 8.674 0 0 0-8.673-8.674Zm0 11.564a2.891 2.891 0 1 1 0-5.783 2.891 2.891 0 0 1 0 5.783Z"
    />
    <path
      fill="#F37A50"
      d="M148.397 25.54a8.673 8.673 0 0 0-8.673 8.673c0 1.886.618 3.617 1.646 5.034.018.034.021.072.042.105l5.782 8.673a1.447 1.447 0 0 0 2.406 0l5.782-8.673c.022-.033.024-.071.043-.105a8.543 8.543 0 0 0 1.646-5.034 8.674 8.674 0 0 0-8.674-8.674Zm0 11.564a2.89 2.89 0 1 1 0-5.782 2.89 2.89 0 0 1 0 5.782Z"
    />
    <path
      stroke="#000"
      strokeDasharray="4 6"
      d="M97.195 49.788s26.487-14.922 51.482 0"
    />
    <defs>
      <linearGradient
        id="a"
        x1={120.437}
        x2={120.437}
        y1={0}
        y2={80.901}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#E5EFEF" />
      </linearGradient>
    </defs>
  </svg>
);
export default EvLogistics;
