import React, { useState } from "react";
import quotes from "../assets/quotes.png";
import bhanu from "../assets/bhanu.png";
import ravi from "../assets/ravi.png";
import praveen from "../assets/praveen.png";

export default function SuccessStories() {
  const reviews = [
    {
      img: bhanu,
      name: "Bhanu Prakash",
      title: "CEO",
      review:
        "I went to OHM Hyderabad Showroom to enquire about electric bikes for me. They clearly explained all variants, and their hospitality is very good. I have purchased an Evoqis Bike, and their service is also very good.",
    },
    {
      img: ravi,
      name: "Ravi",
      title: "Dealer",
      review:
        "I planned to start a dealership for OHM Vehicles. I met Mr. Bala Krishna garu about the dealership, and I got a lot of valuable information. I started my dealership in Bhimavaram and am running my business very successfully.",
    },
    {
      img: praveen,
      name: "Praveen",
      title: "Gadget Freak",
      review:
        "My visit to the OHM Automotive showroom was exceptional. The staff was incredibly knowledgeable and guided me through all details of their electric vehicles with patience and expertise.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === reviews.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="bg-[#E8FCF6] flex flex-col text-center py-10 min-h-80">
      <span className="text-[#1A2E35] text-[48px] sm:text-[128px] font-barlowcondensed font-[275px] leading-[153.6px]">
        Success stories
      </span>
      <p className="text-[#10C06F] text-[24px] sm:text-[40px] leading-[48px] font-barlowcondensed font-normal">
        Customer Reviews
      </p>

      {/* For large screens */}
      <div className="hidden sm:flex justify-center items-center gap-6 mt-6 relative">
        {/* Left Arrow */}
        <button className="absolute left-2 sm:left-0 bg-white rounded-full p-2 shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        {/* Cards */}
        <div className="flex flex-row justify-center items-center gap-6">
          {reviews.map((review, index) => (
            <div
              key={index}
              className="relative w-[300px] sm:w-[350px] h-[410px] bg-white rounded-[15px] border-2 border-[#AEE3C9] shadow-md p-6"
            >
              <img
                src={quotes}
                alt="quotes"
                className="absolute -top-5 right-10"
                width={50}
                height={50}
              />
              <div className="flex flex-col items-center">
                <img
                  src={review.img}
                  alt="profile"
                  className="rounded-full w-24 h-24 mb-4 border-4"
                  style={{ borderColor: "#AEE3C966" }}
                />
                <h3 className="text-lg">{review.name}</h3>
                <h4 className="text-gray-500 tracking-wide">{review.title}</h4>
                <div className="flex py-3">
                  <span className="text-yellow-400 text-[24px]">★</span>
                  <span className="text-yellow-400 text-[24px]">★</span>
                  <span className="text-yellow-400 text-[24px]">★</span>
                  <span className="text-yellow-400 text-[24px]">★</span>
                  <span className="text-gray-400 text-[24px]">★</span>
                </div>
                <p className="text-[#333333] mb-4 text-[16px] tracking-tight font-roboto font-normal leading-[18px]">
                  {review.review}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Right Arrow */}
        <button className="absolute right-2 sm:right-0 bg-white rounded-full p-2 shadow-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>

      {/* For small screens (carousel view) */}
      <div className="sm:hidden relative w-full flex justify-center items-center mt-6">
        {/* Left Arrow */}
        <button
          onClick={handlePrev}
          className="absolute left-2 bg-white rounded-full p-2 shadow-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        {/* Single card for small screens */}
        <div className="relative w-[90%] h-[410px] bg-white rounded-[15px] border-2 border-[#AEE3C9] shadow-md p-6 transition-transform transform duration-300 ease-in-out">
          <img
            src={quotes}
            alt="quotes"
            className="absolute -top-5 right-10"
            width={50}
            height={50}
          />
          <div className="flex flex-col items-center">
            <img
              src={reviews[currentIndex].img}
              alt="profile"
              className="rounded-full w-24 h-24 mb-4 border-4"
              style={{ borderColor: "#AEE3C966" }}
            />
            <h3 className="text-[20px] leading-[24px] font-barlowcondensed font-semibold">{reviews[currentIndex].name}</h3>
            <h4 className="text-gray-500 tracking-wide font-barlowcondensed font-light text-[16px] leading-[19.2px">
              {reviews[currentIndex].title}
            </h4>
            <div className="flex py-3">
              <span className="text-yellow-400 text-[24px]">★</span>
              <span className="text-yellow-400 text-[24px]">★</span>
              <span className="text-yellow-400 text-[24px]">★</span>
              <span className="text-yellow-400 text-[24px]">★</span>
              <span className="text-gray-400 text-[24px]">★</span>
            </div>
            <p className="text-[#333333] mb-4 text-[16px] tracking-tight font-roboto font-normal leading-[18px]">
              {reviews[currentIndex].review}
            </p>
          </div>
        </div>

        {/* Right Arrow */}
        <button
          onClick={handleNext}
          className="absolute right-2 bg-white rounded-full p-2 shadow-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-black"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
