import * as React from "react";
const EvTrans = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={246}
    height={92}
    fill="none"
    {...props}
  >
    <path
      fill="#2A3635"
      d="m202.852 65.353-1.232.353c-2.284-8.048-9.689-13.937-18.471-13.937-8.781 0-15.619 5.46-18.179 13.038l-1.281-.423c2.429-7.37 7.73-14.664 19.253-14.636 12.581.035 17.785 8.159 19.91 15.598v.007ZM62.715 68.177l-.927.18c-1.71-8.845-9.495-15.537-18.844-15.537-9.35 0-17.564 7.066-18.976 16.27l-1.231-.187c1.342-8.47 5.854-18.789 19.833-18.789 13.98 0 18.526 9.786 20.152 18.063h-.007Z"
    />
    <path
      fill="#C7D9D9"
      d="M140.403 37.921c.602 0 1.093.492 1.093 1.094 0 .602-.491 1.093-1.093 1.093h-12.45a1.1 1.1 0 0 1-1.093-1.093c0-.602.491-1.094 1.093-1.094h12.45Z"
    />
    <path
      fill="#F04849"
      d="M149.821 65.035c.609 0 1.114.498 1.114 1.107s-.505 1.114-1.114 1.114h-7.64a1.11 1.11 0 0 1 0-2.221h7.64ZM56.708 42.6c1.02 0 1.848-.509 1.848-1.136 0-.627-.828-1.135-1.848-1.135s-1.848.508-1.848 1.135.828 1.135 1.848 1.135ZM12.522 53.381c1.045 1.218.637 3.266-.893 4.574-1.53 1.308-3.62 1.377-4.657.166-1.045-1.218-.637-3.266.893-4.574 1.529-1.308 3.619-1.377 4.657-.166Z"
    />
    <path
      fill="#FDF0A8"
      d="m208.077 47.346-2-.166a2.366 2.366 0 0 1-1.543-.768c-1.668-1.861-6.173-6.92-6.713-7.875-.664-1.176-4.533-4.851-2.339-5.024 2.187-.166 8.623 1.73 8.623 1.73 1.916 3.938 3.972 12.103 3.972 12.103ZM11.456 43.713c.208-.228.422-.464.65-.692 2.79-2.789 6.063-4.055 7.302-2.817.685.686.602 2-.083 3.51-.554 1.21-1.488 2.539-2.734 3.785-2.789 2.788-6.062 4.055-7.294 2.816-1.135-1.135-.173-3.98 2.16-6.602Z"
    />
    <path
      fill="#173D3D"
      d="M212.457 62.696c-.989 7.335 1.294 4.927 1.052 8.006-.242 3.032-8.962 6.665-9.238 6.775 2.781-2.47 4.463-5.764 5.446-9.197 2.415-8.429.643-17.695.643-17.695 1.391 2.983 2.699 7.599 2.09 12.11h.007Z"
    />
    <path
      fill="#C3E7EC"
      d="m84.48 8.011-30.125 21.73-8.823-.498c8.159-2.845 25.667-14.187 38.94-21.232h.007ZM169.627 20.73c2.582 3.205 0 3.883 0 3.883l-27.653 1.177-.042-.312-3.245-20.207c22.401.187 28.352 12.256 30.94 15.467v-.007ZM133.309 5.215l1.059 20.9-58.082 2.484v-9.038C93.87 5.568 112.936 5.056 133.302 5.215h.007Z"
    />
    <path
      fill="#345655"
      d="M201.62 65.706c.471 1.668.727 3.433.727 5.253 0 10.602-8.589 19.197-19.197 19.197-10.609 0-19.191-8.595-19.191-19.197 0-2.16.353-4.222 1.011-6.16 2.567-7.584 9.737-13.037 18.18-13.037 8.442 0 16.186 5.896 18.47 13.937v.007Zm-3.875 5.26c0-8.042-6.547-14.588-14.595-14.588-8.049 0-14.588 6.546-14.588 14.588 0 8.041 6.546 14.595 14.588 14.595 8.041 0 14.595-6.547 14.595-14.595ZM61.788 68.357a19.32 19.32 0 0 1 .353 3.66c0 10.603-8.595 19.198-19.197 19.198-10.602 0-19.197-8.596-19.197-19.198 0-.996.076-1.972.221-2.927 1.412-9.204 9.37-16.27 18.976-16.27 9.605 0 17.142 6.693 18.844 15.537Zm-4.263 3.66c0-8.048-6.54-14.587-14.588-14.587-8.048 0-14.588 6.54-14.588 14.587 0 8.049 6.54 14.589 14.588 14.589 8.048 0 14.588-6.54 14.588-14.588ZM71.511 20.973l-1.585 8.837-8.643.367a.517.517 0 0 1-.353-.913 201.9 201.9 0 0 1 8.09-6.443c.796-.595 1.626-1.218 2.491-1.848Z"
    />
    <path
      fill="#173D3D"
      d="m142.098 26.78-72.172 3.03 1.585-8.844c7.861-5.744 18.228-12.36 26.512-14.228 15.224-3.44 35.3-3.093 40.857-2.775 7.156.415 22.733.09 33.176 19.059.616 1.12-.159 2.505-1.439 2.56l-28.519 1.197Zm-.118-.99 27.654-1.177s2.581-.678 0-3.882c-2.588-3.204-8.54-15.28-30.941-15.467-1.806-.02-3.598-.035-5.377-.048-20.366-.16-39.432.353-57.016 14.345V28.6l58.082-2.484 7.605-.325h-.007Z"
    />
    <path
      fill="#fff"
      d="M210.354 50.585s1.771 9.266-.644 17.695c0 0 1.211-27.093-25.661-27.093-26.871 0-29.328 22.256-29.328 22.256h-13.979c.464-.803.865-1.675 1.204-2.657 2.893-8.395.789-28.65.159-34l28.519-1.197c1.28-.056 2.055-1.44 1.439-2.56-10.442-18.97-26.02-18.644-33.176-19.06-5.557-.318-25.633-.664-40.857 2.776-8.284 1.875-18.65 8.491-26.512 14.228a340.99 340.99 0 0 0-2.491 1.848l-.838-1.121L90.687 4.918h.014a46.638 46.638 0 0 1 3.267-1.384c14.228-5.419 52.006-3.322 69.583-2.049.755.056 1.495.146 2.229.284v2.588s13.404 5.703 23.66 21.114h5.011c1.529 2.16 2.629 3.779 3.224 4.436 2.776 3.08 3.447 1.17 5.544 3.82.283.36.588.886.885 1.509 0 0-6.435-1.896-8.622-1.73-2.194.173 1.674 3.847 2.339 5.024.54.955 5.045 6.014 6.713 7.875.394.443.948.72 1.543.768l2 .166c.498.146 1.411 1.364 2.283 3.232l-.006.014Z"
    />
    <path
      fill="#A7D16E"
      d="M71.324 70.966h56.954s8.339-.388 12.457-7.523h13.979s2.464-22.255 29.328-22.255c26.865 0 25.661 27.093 25.661 27.093-.983 3.432-2.665 6.726-5.446 9.197 0 0 5.197-31.19-22.222-30.014-17.314.74-20.858 13.301-21.107 22.464h-.02s-1.876 5.135-5.087 5.259c-3.204.125-89.597 0-89.597 0s.186-1.675.083-4.221h5.017Zm79.611-4.824c0-.609-.505-1.107-1.114-1.107h-7.64a1.11 1.11 0 0 0 0 2.221h7.64c.609 0 1.114-.505 1.114-1.114Z"
    />
    <path
      fill="#173D3D"
      d="M204.263 77.478s.402-5.82-1.411-12.132c-2.125-7.44-7.336-15.564-19.91-15.598-11.516-.028-16.823 7.266-19.252 14.636-2.464 7.495-1.952 15.066-1.952 15.066s-.969-4.187-.81-9.537c.256-9.162 3.8-21.722 21.107-22.463 27.419-1.176 22.222 30.02 22.222 30.02l.006.008ZM194.443 25.471h-5.01C179.184 10.06 165.772 4.357 165.772 4.357v-2.58c12.817 2.324 23.357 16.22 28.671 23.701v-.007Z"
    />
    <path
      fill="#173D3D"
      d="M183.15 56.377c8.048 0 14.595 6.547 14.595 14.589 0 8.04-6.547 14.595-14.595 14.595-8.049 0-14.588-6.547-14.588-14.596 0-8.048 6.546-14.588 14.588-14.588Zm12.304 14.589c0-6.782-5.522-12.298-12.304-12.298s-12.298 5.516-12.298 12.298c0 6.781 5.516 12.304 12.298 12.304s12.304-5.523 12.304-12.304Z"
    />
    <path
      fill="#A7D16E"
      d="M183.149 58.661c6.782 0 12.305 5.516 12.305 12.297 0 6.782-5.523 12.305-12.305 12.305-6.781 0-12.297-5.523-12.297-12.305 0-6.781 5.516-12.297 12.297-12.297Zm11.592 13.336v-2.063c0-.975-.9-1.702-1.855-1.48l-7.01 1.619c-.028-.076-.055-.152-.09-.222l6.097-3.82a1.519 1.519 0 0 0 .27-2.36l-1.46-1.46a1.522 1.522 0 0 0-2.367.27l-3.813 6.097c-.063-.028-.139-.062-.208-.09l1.599-6.955a1.522 1.522 0 0 0-1.481-1.861h-2.056a1.52 1.52 0 0 0-1.48 1.861l1.605 6.983c-.055.02-.104.041-.145.062l-3.841-6.145a1.526 1.526 0 0 0-2.367-.27l-1.46 1.46a1.514 1.514 0 0 0 .27 2.36l6.138 3.847c-.034.077-.069.167-.104.257l-7.079-1.634a1.52 1.52 0 0 0-1.862 1.481v2.063a1.52 1.52 0 0 0 1.862 1.48l7.107-1.633c.021.063.049.125.076.187l-6.187 3.876c-.83.512-.955 1.667-.269 2.366l1.46 1.46a1.53 1.53 0 0 0 2.366-.27l3.869-6.18c.062.028.138.063.201.077l-1.647 7.162a1.518 1.518 0 0 0 1.481 1.862h2.055c.976 0 1.702-.907 1.481-1.862l-1.64-7.134c.097-.028.187-.063.263-.104l3.84 6.138a1.518 1.518 0 0 0 2.367.27l1.46-1.46a1.525 1.525 0 0 0-.263-2.367l-6.145-3.84c.021-.056.049-.105.062-.16l7.038 1.62a1.514 1.514 0 0 0 1.855-1.481l.007-.007Z"
    />
    <path
      fill="#173D3D"
      d="M185.876 70.066c.097.27.146.553.146.858 0 .325-.063.636-.18.927-.021.063-.042.104-.063.16a2.551 2.551 0 0 1-1.259 1.252 1.299 1.299 0 0 1-.263.104 2.52 2.52 0 0 1-.831.138c-.311 0-.622-.055-.899-.166a2.353 2.353 0 0 1-.201-.076 2.608 2.608 0 0 1-1.239-1.246c-.027-.062-.055-.117-.076-.187a2.668 2.668 0 0 1-.166-.913c0-.305.049-.581.139-.844.027-.09.062-.18.103-.256a2.578 2.578 0 0 1 1.26-1.253 2.649 2.649 0 0 1 1.086-.242c.312 0 .602.055.872.145.07.028.146.062.208.09.561.256 1.01.713 1.273 1.273.035.063.063.146.09.222v.014ZM161.738 79.457H63.394s.512-5.232-.679-11.273c-1.626-8.27-6.443-18.063-20.152-18.063-13.709 0-18.49 10.319-19.833 18.79-.935 5.902-.34 10.913-.34 10.913s-8.193-.173-14.31-.727c8.67.062 11.065-3.855 11.065-3.855s-1.848-28.56 24.152-28.56c19.695 0 22.706 16.353 23.003 24.29.104 2.54-.083 4.222-.083 4.222s86.393.124 89.598 0c3.204-.125 5.086-5.26 5.086-5.26h.021c-.153 5.343.809 9.537.809 9.537l.007-.014Z"
    />
    <path
      fill="#E5EFEF"
      d="M142.098 26.78c.63 5.349 2.734 25.605-.159 33.999-.339.976-.74 1.854-1.204 2.657-4.111 7.142-12.457 7.523-12.457 7.523H71.324c-7.239-18.879-1.509-40.727-1.398-41.149l72.172-3.03Zm-.602 12.234c0-.602-.491-1.093-1.093-1.093h-12.45a1.1 1.1 0 0 0-1.093 1.093c0 .602.491 1.094 1.093 1.094h12.45c.602 0 1.093-.491 1.093-1.094Z"
    />
    <path
      fill="#173D3D"
      d="m141.98 25.79-7.605.325-1.059-20.9a1432 1432 0 0 1 5.377.049l3.246 20.207.041.312v.006Z"
    />
    <path
      fill="#A7D16E"
      d="M90.68 4.918 68.182 21.7l-22.67 16.906a12.786 12.786 0 0 1-5.96 2.422L19.319 43.72c.685-1.509.768-2.823.083-3.509-1.239-1.238-4.512.021-7.301 2.817-.229.228-.443.464-.65.692H9.9c7.598-11.135 32.663-13.806 32.663-13.806.754 0 1.758-.242 2.969-.671l8.823.505L84.48 8.018c2.2-1.176 4.297-2.221 6.2-3.093v-.007Z"
    />
    <path
      fill="#fff"
      d="M71.324 70.965h-5.017c-.305-7.937-3.315-24.29-23.004-24.29-26.006 0-24.151 28.56-24.151 28.56S16.756 79.16 8.085 79.09h-.02c-.68-.062-1.33-.132-1.939-.194L1.332 65.651S-.025 63.533.224 61.713c.25-1.834.333-2.817 1.64-3.536 1.315-.72.824-4.865 2.014-7.308 1.19-2.443 2.18-4.228 2.18-4.228 1.765-.616 3.855-2.914 3.855-2.914h1.55c-2.325 2.616-3.294 5.46-2.16 6.602 1.233 1.239 4.506-.02 7.295-2.817 1.246-1.245 2.18-2.574 2.733-3.785l20.235-2.692a12.757 12.757 0 0 0 5.959-2.422l22.67-16.907.838 1.122a209.71 209.71 0 0 0-8.09 6.442c-.38.319-.138.928.353.914l8.644-.36c-.11.422-5.841 22.27 1.398 41.148l-.014-.007Zm-12.768-29.5c0-.63-.83-1.136-1.848-1.136-1.017 0-1.848.512-1.848 1.135s.824 1.135 1.848 1.135 1.848-.512 1.848-1.135Zm-46.927 16.49c1.536-1.308 1.938-3.356.893-4.574-1.038-1.218-3.121-1.142-4.658.166-1.536 1.308-1.937 3.356-.892 4.574 1.038 1.218 3.12 1.142 4.657-.166Z"
    />
    <path
      fill="#173D3D"
      d="M42.944 57.43c8.048 0 14.588 6.54 14.588 14.587 0 8.049-6.54 14.589-14.588 14.589-8.049 0-14.588-6.54-14.588-14.588 0-8.049 6.54-14.589 14.588-14.589Zm12.304 14.587c0-6.781-5.522-12.304-12.304-12.304s-12.305 5.523-12.305 12.304c0 6.782 5.523 12.305 12.305 12.305s12.304-5.523 12.304-12.305Z"
    />
    <path
      fill="#A7D16E"
      d="M42.944 59.713c6.782 0 12.304 5.522 12.304 12.304s-5.522 12.305-12.304 12.305-12.305-5.523-12.305-12.305 5.523-12.304 12.305-12.304Zm11.584 13.335v-2.062c0-.976-.906-1.702-1.861-1.48l-7.004 1.612c-.027-.07-.062-.146-.096-.222l6.096-3.813a1.52 1.52 0 0 0 .27-2.367l-1.46-1.46a1.53 1.53 0 0 0-2.367.27l-3.813 6.097c-.062-.035-.138-.063-.207-.09l1.598-6.955a1.525 1.525 0 0 0-1.48-1.862H42.14c-.976 0-1.702.914-1.481 1.862l1.613 6.983c-.056.02-.104.041-.153.062l-3.84-6.145a1.514 1.514 0 0 0-2.36-.27l-1.46 1.46c-.693.692-.561 1.84.262 2.36l6.146 3.847c-.035.076-.07.166-.104.25l-7.087-1.627a1.516 1.516 0 0 0-1.854 1.481v2.063c0 .975.906 1.702 1.854 1.48l7.114-1.633c.021.063.042.125.077.187l-6.187 3.876a1.514 1.514 0 0 0-.27 2.36l1.46 1.46c.685.691 1.841.56 2.367-.27l3.868-6.187c.063.034.132.062.201.083l-1.654 7.162a1.521 1.521 0 0 0 1.481 1.862h2.062c.97 0 1.703-.913 1.481-1.862l-1.64-7.135c.097-.034.18-.062.263-.103l3.84 6.138c.52.83 1.669.962 2.368.27l1.46-1.46a1.52 1.52 0 0 0-.27-2.36l-6.145-3.84c.02-.056.048-.105.069-.16l7.031 1.62a1.519 1.519 0 0 0 1.861-1.482h.014Z"
    />
    <path
      fill="#173D3D"
      d="M45.663 71.118a2.606 2.606 0 0 1-.028 1.792c-.02.063-.048.104-.069.16a2.564 2.564 0 0 1-1.252 1.252c-.083.041-.166.07-.263.104-.263.083-.54.138-.83.138-.312 0-.623-.055-.9-.166a1.025 1.025 0 0 1-.2-.083 2.566 2.566 0 0 1-1.24-1.239c-.034-.062-.055-.124-.076-.186a2.532 2.532 0 0 1 .076-2.014 2.564 2.564 0 0 1 1.253-1.253c.048-.02.104-.048.152-.062.284-.118.602-.18.941-.18.305 0 .603.055.872.145.07.028.146.056.208.09.56.256 1.01.706 1.266 1.267.035.069.063.145.097.221l-.007.014ZM22.397 79.823l-.2 2.547s-16.678.02-17.834-.208c-1.149-.228-1.848-3.896-1.848-3.896.817.242 2.09.45 3.606.637.609.062 1.26.138 1.937.194h.021c6.118.553 14.311.726 14.311.726h.007Z"
    />
    <path
      fill="#173D3D"
      d="M6.12 78.89c-1.515-.18-2.788-.389-3.605-.63C.75 78.217.19 77.642.19 77.642c-.789-4.422 1.135-12 1.135-12l4.796 13.24v.006Z"
    />
    <path
      fill="#3B4454"
      d="M212.028 51.049v1.695h.021l.997-.027c2.726-.063 4.948-2.326 4.948-5.052V29.63a5.338 5.338 0 0 1 5.335-5.335 5.339 5.339 0 0 1 5.336 5.335v32.505c0 3.876 3.155 7.031 7.031 7.031 3.875 0 7.038-3.155 7.038-7.03V28.543a.851.851 0 1 0-1.703 0v33.591a5.342 5.342 0 0 1-5.335 5.336 5.339 5.339 0 0 1-5.336-5.336V29.63c0-3.875-3.155-7.03-7.031-7.03-3.875 0-7.031 3.155-7.031 7.03v18.035c0 1.806-1.474 3.315-3.287 3.356l-.983.028Z"
    />
    <path
      fill="#ABD480"
      d="M237.751 18.703v2.976c0 .394.104.775.291 1.072l1.481 2.374c.186.305.29.678.29 1.073v4.097c0 .927.561 1.674 1.253 1.674h1.619c.692 0 1.253-.747 1.253-1.674v-4.097c0-.395.104-.775.29-1.073l1.481-2.374c.187-.304.291-.678.291-1.072v-2.976c0-.927-.561-1.675-1.253-1.675h-5.737c-.692 0-1.252.748-1.252 1.675h-.007Z"
    />
    <path fill="#fff" d="M240.747 34.17h2.25v-2.25h-2.25v2.25Z" />
    <path
      fill="#3B4454"
      d="M239.267 19.395h5.273c.47 0 .851-.381.851-.851v-.415a.851.851 0 0 0-.851-.852h-5.273a.852.852 0 0 0-.852.852v.415c0 .47.382.851.852.851Z"
    />
  </svg>
);
export default EvTrans;
