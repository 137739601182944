import React, { useState } from "react";
import logo from "../assets/logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav>
      <div className="max-w-7xl mx-auto p-5 sm:px-6 lg:px-18 ">
        <div className="flex justify-center h-16">
          {/* Logo */}
          <div className=" flex items-center ">
            <img className="h-24 w-auto" src={logo} alt="Logo" />
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center justify-between sm:hidden w-full px-4">
            {/* Logo Section */}
            <div className="flex items-center">
              {/* <img className="h-24 w-auto" src={logo} alt="Logo" /> */}
            </div>

            {/* Menu Button */}
            <button
              onClick={toggleMenu}
              className="text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={
                    isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>

          {/* Desktop Menu */}
          <div className="hidden sm:flex sm:items-center sm:ml-6">
            <div className="flex space-x-4">
              <a
                href="#about"
                className="text-black px-3 py-2 rounded-md text-sm font-bold uppercase tracking-wider font-quicksand "
              >
                About
              </a>
              <a
                href="#products"
                className="text-black px-3 py-2 rounded-md text-sm font-medium uppercase tracking-wider"
              >
                Products
              </a>
              <a
                href="#rd"
                className="text-black px-3 py-2 rounded-md text-sm font-medium uppercase tracking-wider"
              >
                Research & Development
              </a>
              <a
                href="#gallery"
                className="text-black px-3 py-2 rounded-md text-sm font-medium uppercase tracking-wider"
              >
                Gallery
              </a>
              <a
                href="#leadership"
                className="text-black px-3 py-2 rounded-md text-sm font-medium uppercase tracking-wider"
              >
                Leadership
              </a>
              <a
                href="#ohmgroup"
                className="text-black px-3 py-2 rounded-md text-sm font-medium uppercase tracking-wider"
              >
                Ohmgroup
              </a>
              <a
                href="#csr"
                className="text-black px-3 py-2 rounded-md text-sm font-medium uppercase tracking-wider"
              >
                CSR
              </a>
              <a
                href="#contact"
                className="text-black px-3 py-2 rounded-md text-sm font-medium uppercase tracking-wider"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="sm:hidden ">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a
              href="#about"
              className="text-black block px-3 py-2 rounded-md text-base font-medium  uppercase tracking-wider"
            >
              About
            </a>
            <a
              href="#products"
              className="text-black block px-3 py-2 rounded-md text-base font-medium  uppercase tracking-wider"
            >
              Products
            </a>
            <a
              href="#rd"
              className="text-black block px-3 py-2 rounded-md text-base font-medium  uppercase tracking-wider"
            >
              Research & Development
            </a>
            <a
              href="#gallery"
              className="text-black block px-3 py-2 rounded-md text-base font-medium  uppercase tracking-wider"
            >
              Gallery
            </a>
            <a
              href="#leadership"
              className="text-black block px-3 py-2 rounded-md text-base font-medium  uppercase tracking-wider"
            >
              Leadership
            </a>
            <a
              href="#ohmgroup"
              className="text-black block px-3 py-2 rounded-md text-base font-medium  uppercase tracking-wider"
            >
              Ohmgroup
            </a>
            <a
              href="#csr"
              className="text-black block px-3 py-2 rounded-md text-base font-medium"
            >
              CSR
            </a>
            <a
              href="#contact"
              className="text-black block px-3 py-2 rounded-md text-base font-medium"
            >
              Contact
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
