import * as React from "react";
const Evcharging = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={182}
    height={124}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M123.213 5.34h40.578c5.699 0 10.319 3.615 10.319 8.057v108.562h-61.437V13.56c0-4.54 4.725-8.228 10.54-8.228v.008Z"
    />
    <path
      fill="#A7D16E"
      d="M162.719 0h-39.466v5.175h39.466V0ZM155.607 14.235h-25.002c-6.902 0-12.496 5.594-12.496 12.495v69.352c0 6.901 5.594 12.496 12.496 12.496h25.002c6.901 0 12.495-5.595 12.495-12.496V26.73c0-6.9-5.594-12.495-12.495-12.495Z"
    />
    <path
      fill="#fff"
      d="M155.284 23.398h-23.096a3.905 3.905 0 0 0-3.905 3.905v8.445a3.905 3.905 0 0 0 3.905 3.905h23.096a3.905 3.905 0 0 0 3.905-3.905v-8.445a3.905 3.905 0 0 0-3.905-3.905Z"
    />
    <path
      fill="#173D3D"
      d="M143.215 102.299c7.586 0 13.735-6.69 13.735-14.943s-6.149-14.943-13.735-14.943c-7.586 0-13.735 6.69-13.735 14.943s6.149 14.943 13.735 14.943Z"
    />
    <path
      fill="#E4F0FA"
      d="M143.215 98.3c5.326 0 9.643-4.9 9.643-10.944 0-6.044-4.317-10.944-9.643-10.944-5.325 0-9.642 4.9-9.642 10.944 0 6.044 4.317 10.944 9.642 10.944Z"
    />
    <path
      fill="#173D3D"
      d="M143.215 93.906c3.186 0 5.769-2.933 5.769-6.55s-2.583-6.55-5.769-6.55-5.769 2.933-5.769 6.55 2.583 6.55 5.769 6.55ZM158.189 41.996h-29.355v26.679h29.355V41.996Z"
    />
    <path
      fill="#A7D16E"
      d="M136.321 44.579h-4.644c-.615 0-1.114.498-1.114 1.114v.53c0 .616.499 1.115 1.114 1.115h4.644c.616 0 1.115-.499 1.115-1.114v-.531c0-.616-.499-1.114-1.115-1.114ZM146.048 44.579h-4.645c-.615 0-1.114.498-1.114 1.114v.53c0 .616.499 1.115 1.114 1.115h4.645c.615 0 1.114-.499 1.114-1.114v-.531c0-.616-.499-1.114-1.114-1.114ZM155.17 44.579h-4.645c-.615 0-1.114.498-1.114 1.114v.53c0 .616.499 1.115 1.114 1.115h4.645c.615 0 1.114-.499 1.114-1.114v-.531c0-.616-.499-1.114-1.114-1.114ZM136.405 51.837h-4.644c-.616 0-1.115.498-1.115 1.114v.531c0 .615.499 1.114 1.115 1.114h4.644c.615 0 1.114-.499 1.114-1.114v-.531c0-.615-.499-1.114-1.114-1.114ZM146.131 51.837h-4.645c-.615 0-1.114.498-1.114 1.114v.531c0 .615.499 1.114 1.114 1.114h4.645c.615 0 1.114-.499 1.114-1.114v-.531c0-.615-.499-1.114-1.114-1.114ZM155.253 51.837h-4.645c-.615 0-1.114.498-1.114 1.114v.531c0 .615.499 1.114 1.114 1.114h4.645c.615 0 1.114-.499 1.114-1.114v-.531c0-.615-.499-1.114-1.114-1.114ZM136.405 58.917h-4.644c-.616 0-1.115.5-1.115 1.115v.53c0 .616.499 1.115 1.115 1.115h4.644c.615 0 1.114-.499 1.114-1.114v-.531c0-.616-.499-1.114-1.114-1.114ZM146.131 58.917h-4.645c-.615 0-1.114.5-1.114 1.115v.53c0 .616.499 1.115 1.114 1.115h4.645c.615 0 1.114-.499 1.114-1.114v-.531c0-.616-.499-1.114-1.114-1.114ZM155.253 58.917h-4.645c-.615 0-1.114.5-1.114 1.115v.53c0 .616.499 1.115 1.114 1.115h4.645c.615 0 1.114-.499 1.114-1.114v-.531c0-.616-.499-1.114-1.114-1.114ZM140.789 27.199h5.206l-1.852 3.348h1.852l-2.923 6.024.32-4.684h-1.566l-1.037-4.688Z"
    />
    <path
      fill="#3B4454"
      d="M112.579 94.666v2.551h-7.646l-2.451-.042c-4.103-.093-7.445-3.498-7.445-7.601V62.437c0-4.425-3.593-8.028-8.029-8.028s-8.028 3.603-8.028 8.028v48.911c0 5.831-4.749 10.579-10.58 10.579-5.832 0-10.59-4.748-10.59-10.579V60.802a1.28 1.28 0 1 1 2.562 0v50.546c0 4.425 3.602 8.028 8.028 8.028 4.425 0 8.028-3.592 8.028-8.028v-48.91c0-5.832 4.749-10.58 10.58-10.58s10.58 4.748 10.58 10.58v27.136c0 2.718 2.218 4.988 4.946 5.05l10.045.042Z"
    />
    <path
      fill="#ABD480"
      d="M65.297 46.005v4.478c0 .594-.156 1.166-.438 1.614l-2.228 3.572a3.094 3.094 0 0 0-.437 1.614v6.164c0 1.396-.844 2.52-1.885 2.52h-2.437c-1.041 0-1.885-1.124-1.885-2.52v-6.164c0-.594-.156-1.166-.437-1.614l-2.228-3.572a3.094 3.094 0 0 1-.438-1.614v-4.478c0-1.395.844-2.52 1.885-2.52h8.633c1.04 0 1.884 1.125 1.884 2.52h.01Z"
    />
    <path fill="#fff" d="M60.788 65.884h-3.385v3.384h3.385v-3.384Z" />
    <path
      fill="#3B4454"
      d="M63.006 43.85h-7.935a1.28 1.28 0 0 0-1.28 1.28v.625c0 .708.573 1.281 1.28 1.281h7.935a1.28 1.28 0 0 0 1.281-1.28v-.625a1.28 1.28 0 0 0-1.28-1.281Z"
    />
    <path
      fill="#7B9D5B"
      d="M64.977 21.82h-.073v-7.501c0-.47-.192-.892-.496-1.203a1.702 1.702 0 0 0-2.901 1.203v7.5H56.69v-7.262a1.7 1.7 0 0 0-.496-1.196 1.682 1.682 0 0 0-1.203-.503c-.931 0-1.698.767-1.698 1.699v7.262h-.185A2.115 2.115 0 0 0 51 23.934v9.575c0 1.163.945 2.115 2.108 2.115H64.97a2.12 2.12 0 0 0 2.115-2.115v-9.575a2.12 2.12 0 0 0-2.115-2.115h.007Zm-9.133-4.323a.636.636 0 0 1-.635.642.642.642 0 0 1-.64-.642v-2.953c0-.35.284-.641.64-.641.357 0 .635.29.635.64v2.954Zm8.498-.145a.636.636 0 0 1-.634.641.642.642 0 0 1-.641-.64v-2.955c0-.35.284-.634.64-.634a.63.63 0 0 1 .635.634v2.954ZM63.813 35.624h-8.881v2.696h8.881v-2.696Z"
    />
    <path stroke="#3B4454" d="M105.384 122.5H181.4" />
    <path
      fill="#2A3635"
      d="m110.261 109.523-.669.192a10.435 10.435 0 0 0-10.04-7.576c-4.773 0-8.49 2.968-9.882 7.087l-.695-.229c1.32-4.007 4.201-7.971 10.464-7.956 6.839.019 9.668 4.435 10.822 8.478v.004ZM34.09 111.058l-.505.098c-.93-4.808-5.16-8.445-10.243-8.445-5.082 0-9.547 3.84-10.314 8.843l-.67-.101c.73-4.604 3.183-10.213 10.781-10.213 7.599 0 10.07 5.319 10.954 9.818h-.004Z"
    />
    <path
      fill="#C7D9D9"
      d="M76.317 94.612a.596.596 0 0 1 0 1.189h-6.768a.598.598 0 0 1-.594-.594c0-.328.267-.595.594-.595h6.767Z"
    />
    <path
      fill="#F04849"
      d="M81.436 109.35c.331 0 .606.271.606.602a.61.61 0 0 1-.606.605h-4.153a.604.604 0 0 1 0-1.207h4.153ZM30.824 97.155c.555 0 1.004-.276 1.004-.617 0-.34-.45-.617-1.004-.617-.555 0-1.004.276-1.004.617 0 .34.45.617 1.004.617ZM6.806 103.015c.568.662.346 1.776-.485 2.487-.831.711-1.967.748-2.532.09-.568-.662-.346-1.775.486-2.486.83-.711 1.967-.749 2.531-.091Z"
    />
    <path
      fill="#FDF0A8"
      d="m113.101 99.736-1.087-.09a1.29 1.29 0 0 1-.839-.418c-.906-1.012-3.355-3.762-3.648-4.281-.361-.64-2.464-2.637-1.272-2.73 1.189-.091 4.687.94 4.687.94 1.042 2.14 2.159 6.579 2.159 6.579ZM6.227 97.76c.113-.124.23-.252.354-.376 1.516-1.516 3.295-2.204 3.968-1.53.373.372.328 1.087-.045 1.906-.3.659-.808 1.381-1.486 2.058-1.515 1.516-3.295 2.204-3.964 1.531-.617-.617-.094-2.163 1.173-3.589Z"
    />
    <path
      fill="#173D3D"
      d="M115.482 108.079c-.538 3.987.704 2.678.572 4.352-.132 1.647-4.871 3.622-5.022 3.682 1.512-1.342 2.426-3.133 2.961-4.999 1.312-4.581.349-9.618.349-9.618.757 1.621 1.467 4.13 1.136 6.583h.004Z"
    />
    <path
      fill="#C3E7EC"
      d="m45.92 78.355-16.375 11.81-4.796-.27c4.435-1.546 13.952-7.711 21.167-11.54h.003ZM92.202 85.268c1.403 1.742 0 2.11 0 2.11l-15.031.64-.023-.17-1.764-10.983c12.176.101 15.411 6.662 16.818 8.407v-.004ZM72.461 76.835l.576 11.36-31.571 1.35v-4.912c9.558-7.606 19.921-7.885 30.991-7.798h.004Z"
    />
    <path
      fill="#345655"
      d="M109.592 109.715c.255.906.395 1.866.395 2.855 0 5.763-4.668 10.435-10.435 10.435s-10.43-4.672-10.43-10.435c0-1.174.19-2.295.548-3.348 1.396-4.123 5.293-7.087 9.882-7.087 4.589 0 8.798 3.205 10.04 7.576v.004Zm-2.107 2.859c0-4.371-3.558-7.93-7.933-7.93s-7.93 3.559-7.93 7.93c0 4.371 3.559 7.933 7.93 7.933 4.371 0 7.933-3.559 7.933-7.933ZM33.585 111.156a10.5 10.5 0 0 1 .192 1.99c0 5.762-4.672 10.434-10.435 10.434-5.762 0-10.434-4.672-10.434-10.434 0-.542.041-1.072.12-1.592.768-5.003 5.093-8.843 10.314-8.843s9.318 3.637 10.243 8.445Zm-2.317 1.99c0-4.375-3.555-7.93-7.93-7.93-4.374 0-7.929 3.555-7.929 7.93 0 4.374 3.555 7.929 7.93 7.929 4.374 0 7.929-3.555 7.929-7.929ZM38.87 85.4l-.86 4.803-4.699.2a.281.281 0 0 1-.192-.497c.835-.7 2.408-2 4.398-3.502.432-.323.883-.662 1.354-1.004Z"
    />
    <path
      fill="#173D3D"
      d="m77.238 88.556-39.23 1.647.862-4.807c4.273-3.122 9.908-6.718 14.41-7.734 8.276-1.87 19.189-1.681 22.21-1.508 3.889.226 12.356.049 18.032 10.36a.94.94 0 0 1-.782 1.391l-15.502.65Zm-.064-.538 15.032-.64s1.403-.368 0-2.11c-1.407-1.741-4.642-8.305-16.818-8.407-.982-.011-1.956-.019-2.923-.026-11.07-.087-21.434.192-30.992 7.797v4.913l31.571-1.35 4.134-.177h-.004Z"
    />
    <path
      fill="#fff"
      d="M114.339 101.496s.963 5.037-.35 9.618c0 0 .659-14.726-13.948-14.726-14.606 0-15.941 12.097-15.941 12.097H76.5c.252-.437.47-.91.655-1.445 1.572-4.562.429-15.573.087-18.48l15.501-.651a.94.94 0 0 0 .782-1.392c-5.676-10.31-14.143-10.133-18.033-10.36-3.02-.172-13.933-.36-22.208 1.51-4.503 1.019-10.138 4.615-14.41 7.733-.467.342-.922.68-1.355 1.004l-.455-.61 12.229-9.12h.007a25.331 25.331 0 0 1 1.776-.753c7.734-2.946 28.268-1.806 37.823-1.114.41.03.812.08 1.21.155v1.406s7.287 3.1 12.862 11.477h2.723c.831 1.174 1.43 2.054 1.753 2.411 1.508 1.674 1.873.636 3.013 2.077.154.195.32.481.482.82 0 0-3.499-1.031-4.687-.94-1.193.093.91 2.09 1.271 2.73.293.52 2.742 3.269 3.649 4.28.214.242.515.392.839.418l1.087.09c.27.08.767.742 1.241 1.757l-.004.008Z"
    />
    <path
      fill="#A7D16E"
      d="M38.768 112.574h30.958s4.533-.211 6.771-4.089h7.599s1.339-12.097 15.941-12.097c14.603 0 13.948 14.726 13.948 14.726-.534 1.866-1.448 3.657-2.96 4.999 0 0 2.825-16.953-12.079-16.314-9.411.403-11.337 7.23-11.473 12.211h-.01s-1.02 2.791-2.766 2.858c-1.741.068-48.7 0-48.7 0s.1-.91.044-2.294h2.727Zm43.274-2.622a.607.607 0 0 0-.606-.602h-4.153a.604.604 0 0 0 0 1.208h4.153a.61.61 0 0 0 .606-.606Z"
    />
    <path
      fill="#173D3D"
      d="M111.029 116.113s.218-3.163-.768-6.594c-1.155-4.043-3.987-8.46-10.822-8.478-6.26-.015-9.144 3.949-10.465 7.955-1.339 4.074-1.06 8.189-1.06 8.189s-.527-2.275-.44-5.183c.139-4.98 2.065-11.808 11.472-12.21 14.904-.64 12.079 16.318 12.079 16.318l.004.003ZM105.691 87.845h-2.724c-5.57-8.377-12.86-11.477-12.86-11.477v-1.403c6.966 1.264 12.695 8.817 15.584 12.884v-.004Z"
    />
    <path
      fill="#173D3D"
      d="M99.552 104.644c4.375 0 7.933 3.559 7.933 7.93 0 4.371-3.558 7.933-7.933 7.933s-7.93-3.559-7.93-7.933c0-4.375 3.559-7.93 7.93-7.93Zm6.688 7.93c0-3.687-3.002-6.685-6.688-6.685a6.691 6.691 0 0 0-6.684 6.685c0 3.686 2.998 6.688 6.684 6.688s6.688-3.002 6.688-6.688Z"
    />
    <path
      fill="#A7D16E"
      d="M99.552 105.885c3.687 0 6.689 2.998 6.689 6.685 0 3.686-3.002 6.688-6.689 6.688-3.686 0-6.684-3.002-6.684-6.688a6.691 6.691 0 0 1 6.684-6.685Zm6.301 7.249v-1.121a.823.823 0 0 0-1.008-.805l-3.81.88a1.432 1.432 0 0 0-.049-.12l3.314-2.076a.825.825 0 0 0 .146-1.283l-.793-.794a.827.827 0 0 0-1.287.147l-2.072 3.314-.113-.049.869-3.78a.827.827 0 0 0-.805-1.012h-1.118a.827.827 0 0 0-.804 1.012l.872 3.795a.818.818 0 0 0-.079.034l-2.088-3.34a.83.83 0 0 0-1.286-.147l-.794.794a.823.823 0 0 0 .147 1.282l3.337 2.092a2.49 2.49 0 0 0-.057.139l-3.848-.888a.826.826 0 0 0-1.012.805v1.121c0 .53.493.925 1.012.805l3.863-.888c.011.034.026.068.041.102l-3.362 2.106a.83.83 0 0 0-.147 1.287l.794.794c.376.372 1 .3 1.286-.147l2.103-3.359c.034.015.075.034.109.041l-.895 3.893a.826.826 0 0 0 .805 1.012h1.117c.53 0 .925-.493.805-1.012l-.892-3.878a.722.722 0 0 0 .143-.056l2.088 3.336a.825.825 0 0 0 1.286.147l.794-.794a.829.829 0 0 0-.143-1.286l-3.34-2.088c.011-.03.026-.056.034-.086l3.825.88a.823.823 0 0 0 1.008-.805l.004-.004Z"
    />
    <path
      fill="#173D3D"
      d="M101.034 112.085c.053.146.079.301.079.466 0 .177-.034.346-.098.504-.011.034-.022.057-.034.087-.139.301-.38.541-.684.68a.71.71 0 0 1-.143.057 1.367 1.367 0 0 1-.452.075c-.169 0-.338-.03-.489-.09a.977.977 0 0 1-.109-.042 1.41 1.41 0 0 1-.673-.677c-.015-.033-.03-.064-.041-.101a1.455 1.455 0 0 1-.09-.497 1.4 1.4 0 0 1 .816-1.279c.022-.011.052-.026.079-.033a1.421 1.421 0 0 1 .985-.019c.038.015.079.034.113.049.305.139.549.387.692.692.019.034.034.079.049.12v.008ZM87.913 117.189H34.458s.278-2.844-.37-6.128c-.883-4.495-3.501-9.817-10.953-9.817s-10.05 5.608-10.78 10.212c-.508 3.209-.185 5.932-.185 5.932s-4.454-.094-7.779-.394c4.713.033 6.015-2.096 6.015-2.096S9.402 99.374 23.534 99.374c10.705 0 12.342 8.889 12.503 13.203.057 1.381-.045 2.295-.045 2.295s46.96.068 48.702 0c1.741-.068 2.764-2.859 2.764-2.859h.011c-.082 2.904.44 5.184.44 5.184l.004-.008Z"
    />
    <path
      fill="#E5EFEF"
      d="M77.238 88.556c.343 2.908 1.486 13.918-.086 18.481a8.352 8.352 0 0 1-.655 1.444c-2.234 3.882-6.77 4.089-6.77 4.089H38.769c-3.935-10.262-.82-22.137-.76-22.366l39.23-1.648Zm-.327 6.65a.596.596 0 0 0-.594-.594H69.55a.598.598 0 0 0-.595.594c0 .328.267.595.595.595h6.767a.596.596 0 0 0 .594-.595Z"
    />
    <path
      fill="#173D3D"
      d="m77.174 88.018-4.134.177-.575-11.36c.97.007 1.94.015 2.923.026l1.764 10.984.022.17v.003Z"
    />
    <path
      fill="#A7D16E"
      d="m49.29 76.673-12.23 9.122-12.322 9.19a6.95 6.95 0 0 1-3.239 1.316L10.5 97.764c.373-.82.418-1.534.046-1.907-.674-.673-2.453.011-3.969 1.531-.124.124-.24.252-.354.376h-.842c4.13-6.052 17.755-7.504 17.755-7.504.41 0 .955-.132 1.613-.365l4.796.275L45.92 78.358a62.119 62.119 0 0 1 3.37-1.681v-.004Z"
    />
    <path
      fill="#fff"
      d="M38.769 112.574h-2.727c-.166-4.315-1.802-13.204-12.504-13.204-14.136 0-13.128 15.524-13.128 15.524s-1.302 2.133-6.015 2.096h-.011c-.369-.034-.722-.072-1.053-.106l-2.607-7.199s-.737-1.151-.602-2.141c.135-.997.18-1.531.892-1.922.714-.391.447-2.644 1.094-3.972a54.425 54.425 0 0 1 1.185-2.298c.96-.335 2.095-1.584 2.095-1.584h.843c-1.264 1.422-1.79 2.968-1.174 3.589.67.673 2.45-.012 3.965-1.531.677-.678 1.185-1.4 1.486-2.058l10.999-1.463a6.933 6.933 0 0 0 3.238-1.317l12.323-9.19.456.61a114.111 114.111 0 0 0-4.398 3.502c-.207.173-.075.504.192.496l4.698-.195c-.06.23-3.174 12.105.76 22.366l-.007-.003Zm-6.94-16.036c0-.342-.452-.617-1.005-.617-.553 0-1.004.278-1.004.617 0 .338.447.617 1.004.617.557 0 1.004-.279 1.004-.617ZM6.32 105.502c.835-.711 1.053-1.825.485-2.487-.564-.662-1.696-.62-2.531.091-.835.711-1.053 1.824-.485 2.486.564.662 1.696.621 2.531-.09Z"
    />
    <path
      fill="#173D3D"
      d="M23.343 105.216c4.374 0 7.929 3.555 7.929 7.929 0 4.375-3.555 7.93-7.93 7.93-4.374 0-7.929-3.555-7.929-7.93 0-4.374 3.555-7.929 7.93-7.929Zm6.688 7.929c0-3.686-3.002-6.688-6.688-6.688-3.687 0-6.689 3.002-6.689 6.688 0 3.687 3.002 6.689 6.689 6.689 3.686 0 6.688-3.002 6.688-6.689Z"
    />
    <path
      fill="#A7D16E"
      d="M23.342 106.457c3.687 0 6.689 3.002 6.689 6.688a6.697 6.697 0 0 1-6.689 6.688 6.697 6.697 0 0 1-6.688-6.688c0-3.686 3.002-6.688 6.688-6.688Zm6.297 7.249v-1.121a.826.826 0 0 0-1.012-.805l-3.806.876a3.058 3.058 0 0 0-.053-.12l3.314-2.073a.826.826 0 0 0 .147-1.286l-.794-.794a.832.832 0 0 0-1.287.147l-2.072 3.314c-.034-.019-.075-.034-.113-.049l.869-3.78a.829.829 0 0 0-.805-1.012h-1.12a.827.827 0 0 0-.806 1.012l.877 3.795c-.03.011-.057.023-.083.034l-2.088-3.34a.823.823 0 0 0-1.283-.147l-.793.794a.824.824 0 0 0 .143 1.282l3.34 2.092c-.019.041-.038.09-.056.135l-3.852-.884a.824.824 0 0 0-1.009.805v1.121c0 .53.493.925 1.009.805l3.866-.888a.582.582 0 0 0 .042.102l-3.363 2.106a.823.823 0 0 0-.147 1.283l.794.794a.828.828 0 0 0 1.287-.147l2.102-3.363a.529.529 0 0 0 .11.045l-.9 3.894a.826.826 0 0 0 .805 1.011h1.121a.828.828 0 0 0 .805-1.011l-.891-3.879c.052-.018.098-.033.143-.056l2.087 3.337a.827.827 0 0 0 1.287.146l.793-.793a.826.826 0 0 0-.146-1.283l-3.34-2.088c.01-.03.026-.056.037-.086l3.822.88a.826.826 0 0 0 1.012-.805h.007Z"
    />
    <path
      fill="#173D3D"
      d="M24.82 112.657a1.417 1.417 0 0 1-.015.974c-.01.034-.026.056-.037.086-.14.301-.38.542-.68.681-.046.023-.091.038-.144.057a1.498 1.498 0 0 1-.451.075c-.17 0-.339-.03-.49-.09a1.394 1.394 0 0 1-.782-.719c-.018-.034-.03-.068-.041-.101a1.382 1.382 0 0 1 .041-1.095c.14-.301.38-.542.681-.681.026-.011.057-.026.083-.034.154-.064.327-.098.512-.098.165 0 .327.03.474.079.037.015.078.03.112.049.305.139.55.384.689.689.019.037.034.079.052.12l-.003.008ZM12.174 117.388l-.109 1.385s-9.065.011-9.693-.113c-.625-.124-1.005-2.118-1.005-2.118.444.132 1.136.245 1.96.346.331.034.685.075 1.053.105h.012c3.325.301 7.779.395 7.779.395h.003Z"
    />
    <path
      fill="#173D3D"
      d="M3.327 116.881c-.824-.098-1.516-.211-1.96-.343-.96-.022-1.264-.334-1.264-.334-.429-2.404.617-6.523.617-6.523l2.607 7.196v.004Z"
    />
  </svg>
);
export default Evcharging;
